/**
* @file
* A JavaScript file for the theme.
*
* In order for this JavaScript to be loaded on pages, see the instructions in
* the README.txt next to this file.
*/

// JavaScript should be made compatible with libraries other than jQuery by
// wrapping it with an "anonymous closure". See:
// - https://drupal.org/node/1446420
// - http://www.adequatelygood.com/2010/3/JavaScript-Module-Pattern-In-Depth
(function ($, Drupal, window, document, undefined) {

  // To understand behaviors, see https://drupal.org/node/756722#behaviors
  Drupal.behaviors.my_custom_behavior = {
    attach: function(context, settings) {


    }
  };


})(jQuery, Drupal, this, this.document);

(function($, window, document) {

  // The $ is now locally scoped

 // Listen for the jQuery ready event on the document
 $(function() {

   // Foundation 6.3 Init
  $(document).foundation();

  /**
  * Menus
  * Handle menu cloning on small devices
  * And sticky menu on all devices
  */
  // Vars
  var b             = document.body;
  var menuToClone   = document.querySelector('#block-menu-block-1 .navbar');
  var secondaryMenu = document.querySelector('.secondary-nav');
  var socialMenu    = document.querySelector('.social-icons');
  var userMenu      = document.querySelector('.user-menu');
  var userAction    = document.querySelector('.user-menu .subscribe-cta > a');
  var menuOpenBtn   = document.querySelector('#trigger-menu');

  if( menuToClone ){
    /**
    * Mobile menu
    */
    // Clone and keep events the primary navigation
    var menuMobile = menuToClone.cloneNode(true);
    menuMobile.classList.add("mobile");
    menuMobile.setAttribute('id','menu-mobile');

    // Some Markup processing to remove unnecessary articles in mobile and clean for DrillDown Menu
    // Unwrap div `navbar__submenu`
    $(menuMobile).find('.navbar__submenu ul').unwrap();
    // Remove mega menu dropdown
    $(menuMobile).find('.view-articles').remove();
    // Clone Processed menu before </body>
    b.appendChild(menuMobile);

    // Adding a background Overlay
    var menuMobileOverlay = document.createElement("div");
    menuMobileOverlay.classList.add('overlay');
    b.appendChild(menuMobileOverlay);

    // Button Open/Close Event Click
    var toggleMenu = function(e){
      e.preventDefault();
      b.classList.toggle('nav-open');
    };
    menuOpenBtn.addEventListener('click', toggleMenu);
    menuMobileOverlay.addEventListener('click', toggleMenu);

    // Foundation 6 Drilldown Init
    // jQuery :( See the doc at
    // @url http://foundation.zurb.com/sites/docs/drilldown-menu.html
    var $drillDownEl = $("#menu-mobile > ul");
    var drillDownOptions = {
      backButton: '<li class="js-drilldown-back"><a tabindex="0">Retour</a></li>',
      closeOnClick: true,
      //scrollTop: true,
      //autoHeight: true,
      //animateHeight: true
    };
    var drillDownInstance = new Foundation.Drilldown( $drillDownEl, drillDownOptions);

    // Finally, clone User menu ans social menu below
    var userMenuMobile = userMenu.cloneNode(true);
    userMenuMobile.classList.add("mobile");
    menuMobile.appendChild(userMenuMobile);

    var socialMenuMobile = socialMenu.cloneNode(true);
    socialMenuMobile.classList.add("mobile");
    menuMobile.appendChild(socialMenuMobile);

    /**
    * Sticky fixed menu
    * Uses headroom.js loaded via Bower
    * @url http://wicky.nillia.ms/headroom.js/
    */
    var stickyContainer     = document.createElement("div");
    var toggledContainer = document.createElement('div');
    toggledContainer.setAttribute('id','toggled__container');

    var stickyHeaderToClone = document.querySelector(".header__content");
    stickyContainer.classList.add('sticky');
    stickyContainer.setAttribute('id','sticky-header');
    b.appendChild(stickyContainer);
    // Clone elements into sticky container
    var stickyMenu          = menuToClone.cloneNode(true);
    var stickyHeader        = stickyHeaderToClone.cloneNode(true);

    stickyContainer.appendChild(stickyHeader);
    stickyContainer.appendChild(toggledContainer);
    toggledContainer.appendChild(stickyMenu);

    if (secondaryMenu) {
      toggledContainer.appendChild(secondaryMenu.cloneNode(true));
      document.querySelector('.sticky .secondary-nav nav').setAttribute('id','okaynav2');
    }

     // Clone bouton abonnement

     var btonMember = userAction.cloneNode( true );
     var targetBton = document.querySelector('.node-type-articles .sticky .header__content__user__actions .share-current');

     if( targetBton ){
      document.querySelector('.node-type-articles .sticky .header__content__user__actions .share-current').appendChild( btonMember )
    }

    /**
    * Level 2 & 3 Nav
    * using okayNav.js only on large +
    * @url https://github.com/VPenkov/okayNav
    * The world "SHITTEST" plugin -,-'
    */

    // create a button to open/close and append it at the correct place
    var stickyNavTriggerBtn = document.createElement("button");
    stickyNavTriggerBtn.textContent = 'Menu';
    stickyNavTriggerBtn.classList.add('button', 'trigger-nav');
    $(stickyNavTriggerBtn).addClass('button trigger-nav'); // IE11 doesnt support classList with multiples classes :(
    stickyNavTriggerBtn.setAttribute('id','sticky-trigger-nav');
    var stickyNavTriggerBtnLocation = document.querySelector(".sticky .header__content__social");
    stickyNavTriggerBtnLocation.appendChild(stickyNavTriggerBtn);
    // Inject article title into sticky fixed menu
    if (b.classList.contains('node-type-articles') || b.classList.contains('node-type-dossiers')){
      var stickyArticleTitle     = document.createElement("div");
      var articleTitleText       = document.querySelector("#page-title").textContent;
      stickyArticleTitle.classList.add('header__content__article-title');
      stickyHeader.appendChild(stickyArticleTitle);
      stickyArticleTitle.textContent = articleTitleText;
    }
    // Open/Close animation
    var stickyNavOpened = false;
    var $stickyMenu = $(stickyMenu);
    var $toggledContainer = $(toggledContainer);
    var toggleStickyNav = function(e){
      e.preventDefault();
      if(!stickyNavOpened){
        // StickyNav is closed, we open it
        stickyNavOpened = true;
        stickyContainer.classList.add('sticky-opened');
        $toggledContainer.velocity('transition.slideDownIn', {
          easing: 'easeOutExpo',
          duration: 300
        });
      } else{
        // StickyNav is open, we close it
        stickyNavOpened = false;
        stickyContainer.classList.remove('sticky-opened');
        $toggledContainer.velocity('transition.slideUpOut', {
          easing: 'easeOutQuart',
          duration: 200
        });
      }
    };
    stickyNavTriggerBtn.addEventListener('click', toggleStickyNav);
    // Sticky Init with Headroom.js
    var wHeight = window.innerHeight;
    var headroom = new Headroom(stickyContainer, {
      offset : wHeight / 3,
      tolerance : {
        up : 5,
        down : 0
      },
      classes : {
        initial : "is-fixed",
        pinned : "pinned",
        unpinned : "unpinned",
        top : "top",
        notTop : "not-top",
        bottom : "bottom",
        notBottom : "not-bottom"
      },
      onTop : function(){
        stickyNavOpened = false;
        stickyContainer.classList.remove('sticky-opened');
        $toggledContainer.velocity('transition.slideUpOut', {
          easing: 'easeOutQuart',
          duration: 200
        });
      }
    });
    headroom.init();

  }

  // Ajout d'un container pour les tables
  if($('.body, .content-subscription').length) {
    $('.body table, .content-subscription table').wrap('<div class="table-container"></div>');
  }


  /**
  * Search
  * Toggle Open/Close Form
  */
  // Function to open/close search form
  var toggleForm = function(e){
    e.preventDefault();
    document.body.classList.toggle('search-opened');
    if($('#edit-search-block-form--2').length){
      $('#edit-search-block-form--2').focus();
    }
    e.stopPropagation();
  };

  $("body").on('click', function(e){

    if ( document.body.classList.contains('search-opened') && e.target.tagName !== "INPUT" && e.target.tagName !== "H2" && e.target.tagName !== "LABEL" && e.target.tagName !== "SELECT" && !e.target.classList.contains('form-item') && !e.target.classList.contains('form-checkboxes') && !e.target.classList.contains('container-inline') ){
      e.stopPropagation();
      document.body.classList.remove('search-opened');
    }

  });
  var searchTriggerBtn = $(".search-btn-trigger");
  var searchCloseBtn = $("#close-search");
  searchTriggerBtn.on('click', toggleForm);


  /**
  * Responsive Videos embeds
  *
  * Support Youtube and Dailymotion
  * Foundation 6 classes
  */
  $( 'iframe[src*="youtube.com"]' ).wrap("<div class='responsive-embed widescreen'/>");
  $( 'iframe[src*="vimeo.com"]' ).wrap("<div class='responsive-embed widescreen vimeo'/>");


  function custom_okaynav_builder(){
    if( $('#okaynav1').length ){
      var $itemsWidth = 0;
      var $menuWidth = $('#okaynav1').width();
      $( '#okaynav1 > ul > li' ).each(function( index ) {
        $itemsWidth += $(this).width();
      });
      if( ($itemsWidth > $menuWidth) && (!$('.okayNav__menu-toggle').length) ){
        $('#okaynav1').addClass('-has-overflow');
        $more = $('<a href="#" class="okayNav__menu-toggle"><span class="arrow">Plus</span></a>').appendTo('#okaynav1');
        $more.on('click', '', function(e){
          e.preventDefault();
          $(this).parent().toggleClass('-is-open');
        });
      } else {
        $('#okaynav1').removeClass('-has-overflow');
      }
    }
  }


  setTimeout(function(){
    custom_okaynav_builder();
  }, 1000);


  $(window).load(function() {
    // Sticky sidebar
    var t = $('.-is-sticky');

    // Get the height of each main element in article "content" bloc
    var rightTopBlock = $('.article__pub');
    var rightTopBlockH = rightTopBlock.outerHeight();
    var rightBottomBlock = $('.articles-conseilles');
    var rightBottomBlockH = rightBottomBlock.outerHeight();
    var leftBlock = $('.left');
    var leftBlockH = leftBlock.outerHeight();
    var rightSideHeight = rightTopBlockH + rightBottomBlockH + 60;

    // Function "initStickyPosition" is active only if the left bloc in article "content"
    // is taller than the right bloc
    if ((t.length > 0 && leftBlockH > rightSideHeight)) {
      $( window ).resize(function() {
        custom_okaynav_builder();
        initStickyPosition();
      });

      function initStickyPosition(){
        if( t.length && $('body').width() >= 1024 && !$('.custom_search_results > h3.title').length){
          var offset = t.offset();
          var p = t.parent();
          var w;
          var l;

          if(p.hasClass('content')) {
            w = (p.width()/100)*28;

            // Position of the sticky element when stickied is equal to :
            // Element position in its parent : 28% of the total width
            // + the width remaining from the window to the element (from the left) :
            // current window width - the container width / 2
            // As we only need the size of the white space on the LEFT side of the screen
            l = ((p.width()/100)*72) + ( ($(window).width() - p.width()) / 2 );
          } else {
            w = p.width();

            // Same but get the parent parent element
            l = ((p.parents('.content').width()/100)*72) + (($(window).width() - p.parents('.content').width()) / 2);
          }

          t.css({
            'left': l,
            'width': w
          });
        } else {
          t.removeClass('-is-sticked');
          t.removeClass('-is-bottom');
          t.attr('style', '');
        }
      }

      function stickyPosition(){
        var p = t.parent();
        var po = p.offset();
        var s = $( window ).scrollTop();
        var menuHeight;
        var offsetBottom;

        if( ($('body').width() >= 1024) && ( $( window ).height() > t.outerHeight() + 150) && !$('.custom_search_results > h3.title').length ){
          menuHeight = 100;
          var $stickBottom = t.addClass('-is-bottom');

          // If page = page article :
          // Just the ad stays sticky and has an offset at the bottom
          // to avoid being behind the "Nous vous conseillons aussi" block
          if($('.article__pub').length && $('.articles-conseilles').length) {
            var $blocArticlesHeight = $('.articles-conseilles').outerHeight();
            // $menuHeight :
            // topmenu height + bloc articles "nous vous conseillons aussi" height + margin top
            menuHeight = 100 + $blocArticlesHeight + 60;
            offsetBottom = $blocArticlesHeight + 60;
            $stickBottom = t.addClass('-is-bottom').css({ 'bottom': offsetBottom });
          }

          (s + menuHeight >= ( po.top + p.outerHeight() - t.outerHeight())) ? $stickBottom : t.removeClass('-is-bottom');
          s + 100 > po.top ?  t.addClass('-is-sticked') : t.removeClass('-is-sticked');
        }
      }

      initStickyPosition();

      $( window ).scroll(function(e){
        stickyPosition();
      });
    } else if (leftBlockH < rightSideHeight) {
      rightBottomBlock.css('position', 'initial');
    }
  });

  // aliment anchors
  // $(window).bind('hashchange', function () {
  //   var hash = window.location.hash.slice(1);
  //   var aTag = $("[name='"+ hash +"'], #"+ hash +"");
  //   if( aTag.length ){
  //     $('html,body').animate({ scrollTop: aTag.offset().top - 80 }, 800);
  //     $("#aliment_accordeon").foundation('down', $(".accordion-content[id='"+ hash +"']"), true);
  //   }
  // });


  // Scroll to anchors
  $('a[href^="#"]').click(function(e) {
    var position;

    if(!$('.node-type-aliments').length){
      position = $($(this).attr('href')).offset().top - 80;
      var hash = $(this).attr('href').split('#')[1];
      $('#references').foundation('down', $(".accordion-content"), true);
    } else {
      var hash = $(this).attr('href').split('#')[1];
      position = $("[name='"+ hash +"']").offset().top - 80;
      if($('#aliment_accordeon').length) {
        $('#aliment_accordeon').foundation('down', $(".accordion-content[id='"+ hash +"']"), true);
      }
    }

  	$('body, html').animate({
  		scrollTop: position
  	}, 800 );
  });

  $('#toggled__container .navbar > ul > li').each(function( index ) {
    if($( this ).hasClass('is-active')){
      var activeClasses = $( this ).attr("class");
      $('#toggled__container .menu-level-3 ul').addClass(activeClasses);
      $('#toggled__container .menu-level-2 ul').addClass(activeClasses);
    }
  });

  // Petition
  if ($('body').hasClass('node-type-petition')) {
    // Sticky counter
    if($('.view-petition-counter').length) {
      var petitionCounter = document.querySelector('.view-petition-counter');
      var counterOffset = $('.view-petition-counter').offset().top - 60;
      var headroom = new Headroom(petitionCounter, {
        offset: counterOffset,
        classes : {
          notTop: 'view-petition-counter--sticky',
        },
      });
      headroom.init();
    }

    // Scroll to form
    if($('.webform-client-form').length) {
      $('.js-scrollto-webform').click(function(e) {
        e.preventDefault();
        var position =  $('.webform-client-form').offset().top - 120;
        $('body, html').animate({
          scrollTop: position
        }, 800 );
      });

      // Hides sticky bar on mobile
      const target = document.querySelector('.webform-client-form');
      const stickyBar = document.querySelector('.view-petition-counter');

      function handleIntersection(entries) {
        entries.map((entry) => {
          if (entry.isIntersecting) {
            stickyBar.classList.add('hidden')
          } else {
            stickyBar.classList.remove('hidden')
          }
        });
      }
      
      const observer = new IntersectionObserver(handleIntersection);
      observer.observe(target);
      
    }

  }
 
});

 // The rest of the code goes here!

}(window.jQuery, window, document));
// The global jQuery object is passed as a parameter
